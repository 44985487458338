import classNames from "classnames";
import { Validators } from "./fields";
import { useFormikHandler } from "./form_handler";
import Header from "./header";
import CampaignInfo from "./steps_side_nav";
import Tooltip from "./tooltip";
import { useCampaign, useShowOnboardingNav } from "./utils";

const Platform = () => {
  useShowOnboardingNav();
  const { campaign, unavailable } = useCampaign();
  const formik = useFormikHandler({
    campaign,
    fields: ["platform"],
    validators: {
      platform: Validators.required,
    },
  });

  if (!campaign) {
    return unavailable;
  }

  return (
    <div id="main" className="container">
      <Header percent={5} />
      <CampaignInfo campaign={campaign} />

      <div className="row">
        <div className="col">
          <div className="page-subhead mb-5">
            <h1>Select Your Campaign Platform</h1>
            <h2>
              Choose the platform where your campaign will be centered. Creators may still post on other platforms, but this will be the main focus for your campaign.
            </h2>
            <p>
              <Tooltip
                className="link-like"
                tip="We focus on primary-platform campaigns for better measurability. We will still present you with creators who are active on multiple platforms."
              >
                Why can't I select multiple platforms?
              </Tooltip>
            </p>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="row row-cols-1 row-cols-md-4 g-3" data-form-field="platform">
              {[
                { value: "tiktok", style: { backgroundColor: "black" } },
                { value: "instagram", style: { backgroundColor: "white" } },
                { value: "youtube", style: { backgroundColor: "white" } },
                { value: "x", style: { backgroundColor: "black" } },
                { value: "linkedin", style: { backgroundColor: "white" } },
              ].map((option) => (
                <div key={option.value}>
                  <button
                    className={classNames("card card-selectable h-100 text-center", {
                      on: option.value === formik.values.platform,
                    })}
                    style={option.style}
                    onClick={() => {
                      formik
                        .setFieldValue("platform", option.value)
                        .then(() => formik.handleSubmit());
                    }}
                  >
                    <div className="card-body">
                      <img
                        src={`/images/logo_${option.value}.png`}
                        className="img-fluid"
                        alt="logo_tiktok"
                      />
                    </div>
                  </button>
                </div>
              ))}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Platform;

import classNames from "classnames";
import { useEffect } from "react";
import { useApiGet } from "../../utils/api";

export const campaignUuid = () => location.pathname.split("/")[2];

export const useCampaign = () => {
  const uuid = location.pathname.split("/")[2];
  const { data, unavailable, refetch } = useApiGet(`/campaigns/${uuid}/form`);

  return { campaign: data?.campaign, unavailable, refetch };
};

export const formatPlatform = (platform) =>
  ({
    tiktok: "TikTok",
    instagram: "Instagram",
    youtube: "YouTube",
    x: "X",
    linkedin: "LinkedIn",
  }[platform]);

export const useShowOnboardingNav = () => {
  useEffect(() => {
    document
      .querySelectorAll(".campaign-menu-item")
      .forEach((e) => e.classList.remove("d-none"));
    return () => {
      document
        .querySelectorAll(".campaign-menu-item")
        .forEach((e) => e.classList.add("d-none"));
    };
  }, []);
};

export const useTemplate2 = () => {
  useEffect(() => {
    document.body.classList.replace("template-2", "template-1");
    return () => {
      document.body.classList.replace("template-1", "template-2");
    };
  }, []);
};

export const PlatformIcon = ({
  platform,
  handle,
  className,
  handleClass,
  contrast,
  self,
}: {
  platform: string;
  handle?: string;
  className?: string;
  handleClass?: string;
  contrast?: boolean;
  self?: boolean;
}) => {
  const icon = { x: "twitter-x" }[platform] || platform;

  return (
    <div className={classNames(`platform-icon ${platform}`, className, { contrast })}>
      <i className={classNames(`bi bi-${icon}`)} />
      {handle && <span className={handleClass}>{handle}</span>}
      {self && <span>{formatPlatform(platform)}</span>}
    </div>
  );
};

export const SizeBadge = ({ size }) => (
  <span className={`badge badge-size badge-${size}`}>{size}</span>
);

export const ProgressCircle = ({
  value,
  size,
  className,
}: {
  value: number;
  size?: "sm" | "lg";
  className?: string;
}) => {
  const rotation = (value / 100) * 360 - 90;
  const secondHalf = value > 50;

  return (
    <>
      <div
        className={classNames("progress-circle", className, {
          "progress-circle-over-50": secondHalf,
          "progress-circle-lg": size === "lg",
        })}
      >
        <div
          className="circle-semi circle-semi-progress"
          style={{
            transform: `rotate(${rotation}deg)`,
          }}
        ></div>
        <div className="circle-semi circle-semi-left" />
        <div className="circle-semi circle-semi-right" />
      </div>
    </>
  );
};

export const formatDate = (date: string) =>
  new Date(date).toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

import HomeIndex from "./home/index";
import CampaignsIndex from "./campaigns/index";
import CampaignsShow from "./campaigns/show";
import CampaignsIntro from "./campaigns/intro";
import CampaignsBudget from "./campaigns/budget";
import CampaignsPlatform from "./campaigns/platform";
import CampaignsContentTypes from "./campaigns/content_types";
import CampaignsCreatorProfile from "./campaigns/creator_profile";
import CampaignsCreatorsDiscovered from "./campaigns/creators_discovered";
import CampaignsBrief from "./campaigns/campaign_brief";
import CampaignsCreatorBrief from "./campaigns/creator_brief";
import CampaignsReview from "./campaigns/review";
import CampaignsDetails from "./campaigns/details";
import CampaignsCheckout from "./campaigns/checkout";
import PackagesShow from "./packages/show";
import AdminImportCreators from "./admin/import_creators";
import AdminUsersIndex from "./admin/users/index";
import AdminCampaignsIndex from "./admin/campaigns/index";
import AdminCampaignsShow from "./admin/campaigns/show";
import AdminAgenciesIndex from "./admin/agencies/index";
import AdminCreators from "./admin/creators";
import AdminViewCreator from "./admin/creators/view_creator";
import AdminSystemSettings from "./admin/system_settings/index";

const AppRoutes = {
  "/": HomeIndex,
  "/campaigns": CampaignsIndex,
  "/campaigns/:uuid": CampaignsShow,
  "/campaigns/intro": CampaignsIntro,
  "/campaigns/:uuid/budget": CampaignsBudget,
  "/campaigns/:uuid/platform": CampaignsPlatform,
  "/campaigns/:uuid/content_types": CampaignsContentTypes,
  "/campaigns/:uuid/creator_profile": CampaignsCreatorProfile,
  "/campaigns/:uuid/creators_discovered": CampaignsCreatorsDiscovered,
  "/campaigns/:uuid/campaign_brief": CampaignsBrief,
  "/campaigns/:uuid/creator_brief": CampaignsCreatorBrief,
  "/campaigns/:uuid/review": CampaignsReview,
  "/campaigns/:uuid/details": CampaignsDetails,
  "/campaigns/:uuid/packages/:packageId": PackagesShow,
  "/campaigns/:uuid/packages/:packageId/checkout": CampaignsCheckout,
  // Admin
  "/admin/import_creators": AdminImportCreators,
  "/admin/users": AdminUsersIndex,
  "/admin/campaigns": AdminCampaignsIndex,
  "/admin/campaigns/:uuid": AdminCampaignsShow,
  "/admin/agencies": AdminAgenciesIndex,
  "/admin/creators": AdminCreators,
  "/admin/creators/:uuid": AdminViewCreator,
  "/admin/system_settings": AdminSystemSettings,
};

export default AppRoutes;

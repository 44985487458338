import { useFormik } from "formik";
import { apiDelete, apiPost, apiPut, useApiGet } from "../../../utils/api";
import { MultiSelect, SelectField, TextField } from "../../campaigns/fields";
import { toast } from "react-toastify";
import { categories, platforms, countries, ageRanges } from "../../campaigns/lists";
import { Drawer } from "../../../utils/offcanvas";
import { lorem, useDevTool } from "../../../utils/dev_tools";
import { useState } from "react";

const mockValues = {
  platform: "instagram",
  handle: lorem(2).replace(" ", "_"),
  categories: ["Actor", "Activist"],
  followers: 1000,
  impressions: 2000,
  interactions: 3000,
  first_top_age_range: "1-17",
  first_top_age_percentage: 40,
  second_top_age_range: "18-24",
  second_top_age_percentage: 40,
  third_top_age_range: "25-34",
  third_top_age_percentage: 20,
  female_percentage: "40",
  male_percentage: "40",
  other_percentage: "20",
};

const PlatformForm = ({ object, parent, onSuccess, setObject }) => {
  if (object === undefined) {
    return null;
  }

  const [addAnother, setAddAnother] = useState(false);

  let data, unavailable;

  const urlBase = `/admin/creators/${parent.uuid}/creator_platforms`;

  if (object.uuid) {
    ({ data, unavailable } = useApiGet(`${urlBase}/${object.uuid}`));
  }

  const formik = useFormik({
    initialValues: data?.creator_platform || {},
    enableReinitialize: true,
    onSubmit: (values) => {
      let method: typeof apiPut | typeof apiDelete, path: string;

      if (object.uuid) {
        [method, path] = [apiPut, `${urlBase}/${object.uuid}`];
      } else {
        [method, path] = [apiPost, `${urlBase}`];
      }
      method(path, { creator_platform: values }).then(({ data }) => {
        if (data.success) {
          onSuccess();
          if (addAnother) {
            setObject({});
            document
              .querySelector(".offcanvas-body")
              .scrollTo({ top: 0, behavior: "smooth" });
          } else {
            setObject();
          }
          toast("Saved");
        }
      });
      formik.setValues({ object_platforms_attributes: [{}] });
    },
  });

  useDevTool(() => formik.setValues(mockValues));

  if (unavailable) {
    return unavailable;
  }

  return (
    <Drawer
      show={!!object}
      onHide={() => setObject(undefined)}
      title={`${object?.uuid ? "Edit" : "Add"} Handle`}
    >
      <form onSubmit={formik.handleSubmit}>
        <SelectField
          name="platform"
          label="Platform"
          options={platforms}
          formik={formik}
          required
        />

        <TextField
          label="Handle"
          name="handle"
          className="mt-4"
          prepend="@"
          formik={formik}
          required
        />

        <MultiSelect
          label="Categories"
          name="categories"
          options={categories}
          theme="light"
          closeMenuOnSelect
          className="mt-4"
          formik={formik}
        />

        <TextField
          label="Followers"
          name="followers"
          type="number"
          className="mt-4"
          formik={formik}
          required
        />

        <TextField
          label="Predicted Impressions"
          name="impressions"
          type="number"
          className="mt-4"
          formik={formik}
        />

        <TextField
          label="Predicted Interactions"
          name="interactions"
          type="number"
          className="mt-4"
          formik={formik}
        />

        <div className="mb-3">
          <label className="form-label mt-4 mb-0">Top audience ages</label>
          <table className="table table-sm">
            <thead>
              <tr>
                <th className="fw-normal small">Age range</th>
                <th className="fw-normal small">% Total age range</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <SelectField
                    name="first_top_age_range"
                    options={ageRanges}
                    formik={formik}
                  />
                </td>
                <td>
                  <TextField
                    name="first_top_age_percentage"
                    type="number"
                    append="%"
                    max="100"
                    min="0"
                    formik={formik}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <SelectField
                    name="second_top_age_range"
                    options={ageRanges}
                    formik={formik}
                  />
                </td>
                <td>
                  <TextField
                    name="second_top_age_percentage"
                    type="number"
                    append="%"
                    max="100"
                    min="0"
                    formik={formik}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <SelectField
                    name="third_top_age_range"
                    options={ageRanges}
                    formik={formik}
                  />
                </td>
                <td>
                  <TextField
                    name="third_top_age_percentage"
                    type="number"
                    append="%"
                    max="100"
                    min="0"
                    formik={formik}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mb-3">
          <label className="form-label mb-0">Top audience genders</label>
          <table className="table table-sm">
            <thead>
              <tr>
                <th className="fw-normal small">Gender</th>
                <th className="fw-normal small">% of total genders</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Female</td>
                <td>
                  <TextField
                    name="female_percentage"
                    type="number"
                    append="%"
                    max="100"
                    min="0"
                    formik={formik}
                  />
                </td>
              </tr>
              <tr>
                <td>Male</td>
                <td>
                  <TextField
                    name="male_percentage"
                    type="number"
                    append="%"
                    max="100"
                    min="0"
                    formik={formik}
                  />
                </td>
              </tr>
              <tr>
                <td>Other</td>
                <td>
                  <TextField
                    name="other_percentage"
                    type="number"
                    append="%"
                    max="100"
                    min="0"
                    formik={formik}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mb-3">
          <label className="form-label mb-0">Top audience locations</label>
          <table className="table table-sm">
            <thead>
              <tr>
                <th className="fw-normal small">Country</th>
                <th className="fw-normal small">% of total locations</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <SelectField
                    name="first_top_country_name"
                    options={countries}
                    formik={formik}
                  />
                </td>
                <td>
                  <TextField
                    name="first_top_country_percentage"
                    type="number"
                    append="%"
                    max="100"
                    min="0"
                    formik={formik}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <SelectField
                    name="second_top_country_name"
                    options={countries}
                    formik={formik}
                  />
                </td>
                <td>
                  <TextField
                    name="second_top_country_percentage"
                    type="number"
                    append="%"
                    max="100"
                    min="0"
                    formik={formik}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <SelectField
                    name="third_top_country_name"
                    options={countries}
                    formik={formik}
                  />
                </td>
                <td>
                  <TextField
                    name="third_top_country_percentage"
                    type="number"
                    append="%"
                    max="100"
                    min="0"
                    formik={formik}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {object.id && <div className="mt-2 gray">id: {object.id}</div>}

        <div className="mt-4">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setObject(undefined)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={() => setAddAnother(false)}
          >
            Save
          </button>
          {!object.id && (
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={() => {
                setAddAnother(true);
              }}
            >
              Save and add another
            </button>
          )}
        </div>
      </form>
    </Drawer>
  );
};

export default PlatformForm;

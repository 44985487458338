import Header from "./header";
import CampaignInfo from "./steps_side_nav";
import { useCampaign, useShowOnboardingNav } from "./utils";
import { CheckboxGroup, MultiSelect, RadioGroup, TextArea, Validators } from "./fields";
import ActionButtons from "./action_buttons";
import Tooltip from "./tooltip";
import { HelpLinkDrawer } from "../../utils/offcanvas";
import { useFormikHandler } from "./form_handler";

const ADD_ONS = [
  [
    "Link in bio",
    "Direct followers to a link provided in the influencer's bio for more information or purchase.",
  ],
  [
    "Amazon affiliate link",
    "Use a specific Amazon link to promote a product and earn a commission on sales.",
  ],
  [
    "Custom affiliate link",
    "Use a unique link to track sales or leads generated by the influencer.",
  ],
  [
    "Share promo code",
    "Provide a discount code that followers can use for a special offer or deal.",
  ],
  ["Follow us", "Encourage followers to follow the brand’s social media account."],
  ["Click the link", "Encourage followers to click on a link to learn more."],
  ["Sign up", "Direct followers to sign up for a newsletter, service, or event."],
  ["Download", "Prompt followers to download an app or a digital resource."],
  ["Watch now", "Encourage followers to watch a video or livestream."],
  ["TikTok Shop link", "Direct followers to a Tik Tok shop."],
  ["Visit website", "Prompt followers to visit the brand's website."],
  ["Enter giveaway", "Encourage followers to participate in a contest or giveaway."],
  ["DM for details", "Invite followers to send a direct message for more information."],
  ["Save this post", "Ask followers to save the post for future reference."],
  ["Share this post", "Ask followers to share this post with their friends."],
  ["Comment below", "Engage followers by asking them to comment on the post"],
  [
    "No CTA - Brand Awareness Only",
    "Focus solely on increasing brand visibility without any direct call-to-action.",
  ],
].map((o) => ({
  value: o[0],
  label: (
    <>
      {o[0]}
      <Tooltip className="link-like" tip={o[1]}>
        <i className="bi bi-question-circle small text-muted ml-1" />
      </Tooltip>
    </>
  ),
}));

const DISCLOSURES = [
  [
    "Paid partnership label",
    "A label provided by social media platforms to indicate a sponsored collaboration, visible to viewers.",
  ],
  [
    "Collaborator tag",
    "Tagging the brand or partner directly in the post or story, indicating a collaboration.",
  ],
  [
    "Disclosure in caption (i.e. #partner or #sponsored)",
    "Including a disclosure hashtag (e.g., #partner or #sponsored) in the post caption to indicate a paid partnership.",
  ],
  [
    "Verbal disclosure in video",
    "Verbally disclosing the partnership at the beginning or end of a video post.",
  ],
].map((o) => ({
  value: o[0],
  label: (
    <>
      {o[0]}
      <Tooltip className="link-like" tip={o[1]}>
        <i className="bi bi-question-circle small text-muted ml-1" />
      </Tooltip>
    </>
  ),
}));

const Guidelines = () => {
  const points = [
    "Do not use profanity",
    "Align with brand values",
    "Avoid controversy",
    "Use high-quality visuals",
    "Use clear, error-free captions",
    "Follow platform guidelines",
    "Include a clear CTA if required",
    "Obtain permission for all copyright materials",
    "Ensure authenticity and clarify",
    "Avoid overly promotionial language",
    "Use audience-friendly language",
    "Disclose sponsorships",
  ];
  return (
    <div>
      <div className="mb-4">
        We'll notify all eligible creators to abide by our general guidelines to
        participate in any campaign.
      </div>

      {points.map((p) => (
        <div key={p}>
          <i className="bi bi-check-lg green mr-2" />
          {p}
        </div>
      ))}
    </div>
  );
};

const CreatorObjectiveHelp = () => {
  return (
    <>
      <dl>
        <dt>Sponsored Post or Content:</dt>
        <dd>
          A piece of content (image, video, or text) where the influencer promotes a
          product or service, typically including hashtags and a call-to-action.
        </dd>

        <dt>Short-Form Video:</dt>
        <dd>
          Engaging, quick video content (15-90 seconds) designed to showcase a product or
          participate in trends. Works well for product demos, challenges, or awareness
          campaigns.
        </dd>

        <dt>Long-Form Video Content:</dt>
        <dd>
          In-depth video content (5-20 minutes) focused on product reviews, tutorials, or
          detailed storytelling, providing a comprehensive look at the brand.
        </dd>

        <dt>Stories or Temporary Content:</dt>
        <dd>
          Short-lived, real-time content that disappears after 24 hours, perfect for
          showcasing behind-the-scenes moments, quick promotions, or product demos.
        </dd>

        <dt>Livestreams:</dt>
        <dd>
          Real-time video or audio broadcasts where influencers engage directly with their
          audience through Q&A, product demos, or event coverage.
        </dd>

        <dt>
          Action-Oriented Content: Event or Location Promotion (Combined from Attend Event
          List):
        </dt>
        <dd>
          Influencers create content encouraging followers to attend an event or visit a
          business location. This can include:
          <ul>
            <li>Event Promotion: Promoting physical or virtual events.</li>
            <li>
              Location Visit: Driving foot traffic to a business with in-person content.
            </li>
            <li>
              Livestream Event Coverage: Real-time event broadcasting with audience
              interaction.
            </li>
          </ul>
          Example: A series of Instagram Stories or TikTok videos promoting the event or
          location, possibly with a discount code or special offer.
        </dd>

        <dt>User-Generated Content (UGC):</dt>
        <dd>
          Influencers encourage followers to create and share content related to a
          campaign, increasing reach and engagement. This content can be reshared by the
          brand to amplify visibility.
        </dd>
      </dl>
    </>
  );
};

const CreatorBrief = () => {
  useShowOnboardingNav();
  const { campaign, unavailable } = useCampaign();

  const formik = useFormikHandler({
    campaign,
    fields: [
      "campaign_purposes",
      "talking_points",
      "hashtags",
      "required_actions",
      "add_ons",
      "partnership_disclosures",
      "additional_guidelines",
      "creator_sample",
      "additional_instructions",
      "creator_sample_return",
    ],
    validators: {
      campaign_purposes: Validators.atLeastOneInGroup,
      talking_points: Validators.required,
      hashtags: Validators.required,
      required_actions: Validators.required,
      add_ons: Validators.atLeastOneInGroup,
      partnership_disclosures: Validators.atLeastOneInGroup,
      creator_sample: Validators.requiredRadio,
      // additional_instructions: Validators.required,
      // creator_sample_return: Validators.requiredRadio,
    },
  });

  if (unavailable) {
    return unavailable;
  }

  return (
    <div id="main" className="container">
      <Header percent={65} />
      <CampaignInfo campaign={campaign} />

      <div className="row">
        <div className="col">
          <div className="page-subhead mb-4">
            <h1>Set Up Your Creator Brief</h1>
            <h2>
              Provide essential details to guide your creators for campaign success.
            </h2>
          </div>

          <form onSubmit={formik.handleSubmit} className="form-container">
            <label>Creator's Objective</label>
            <div className="form-text">
              Outline the key task(s) for the creator. Keep it simple for the best
              results.{" "}
              <HelpLinkDrawer
                title="About Creator's Objectives"
                text="About creator's objectives"
                content={<CreatorObjectiveHelp />}
                closeButton
              />
            </div>
            <MultiSelect
              options={[
                "Sponsored Post or Content",
                "Short-Form Video",
                "Long-Form Video Content",
                "Stories or Temporary Content",
                "Livestreams",
                "Action-Oriented Content",
                "User-Generated Content (UGC)",
              ]}
              name="campaign_purposes"
              formik={formik}
            />

            <TextArea
              label="Talking points"
              sublabel="List the key messages or talking points the creator should cover in their content."
              name="talking_points"
              formik={formik}
              className="mt-4"
              placeholder="Unique Features: Discuss the standout features of the product, such as its durability or ease of use."
            />

            <TextArea
              label="Campaign hashtags"
              sublabel="Add key hashtags to enhance reach and engagement. Avoid using too many, as it may limit the campaign’s effectiveness."
              name="hashtags"
              className="mt-4"
              formik={formik}
            />

            <TextArea
              label="Key Tasks for Creators"
              sublabel="List essential tasks the creator needs to complete. Fewer, focused actions often lead to better results."
              name="required_actions"
              placeholder="Create a Product Review Video: Task: Film a 60-second video reviewing the product, highlighting its key features and benefits."
              className="mt-4"
              formik={formik}
            />

            <CheckboxGroup
              label="Call to action"
              sublabel="Additional features you would like to be included by the creator"
              name="add_ons"
              options={ADD_ONS}
              className="mt-4"
              formik={formik}
            />

            <CheckboxGroup
              label="Partnership disclosures"
              className="mt-4"
              sublabel="How would you like the creators to disclose the paid partnership?"
              name="partnership_disclosures"
              options={DISCLOSURES}
              formik={formik}
            />

            <TextArea
              label="Guidelines"
              sublabel={
                <>
                  We've got you covered with our{" "}
                  <HelpLinkDrawer
                    title="General Guidelines"
                    text="general guidelines"
                    append=". Add any additional guidelines below."
                    content={<Guidelines />}
                  />
                </>
              }
              name="additional_guidelines"
              formik={formik}
              className="mt-4"
            />

            <RadioGroup
              label="Will the Creators be receiving a product sample to demonstrate and complete this campaign?"
              name="creator_sample"
              options={["Yes", "No"]}
              formik={formik}
              className="mt-4"
            />

            {formik.values.creator_sample === "Yes" && (
              <>
                <TextArea
                  label="Product Preferences"
                  sublabel="What information do you need from creators to provide them with product"
                  name="additional_instructions"
                  placeholder="i.e. T-shirt size, select preferred product sample, or provide context for your post"
                  formik={formik}
                  className="mt-4"
                />
                <RadioGroup
                  label="Do you need this product back from the creator?"
                  name="creator_sample_return"
                  options={["Yes", "No"]}
                  formik={formik}
                  className="mt-4"
                />
              </>
            )}

            <ActionButtons />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreatorBrief;

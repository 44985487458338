import { useState } from "react";
import { TextField } from "../../campaigns/fields";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { debounce, pull, uniq } from "lodash";
import { genericError } from "../../../utils/errors";
import { apiGet, apiPost, apiPut } from "../../../utils/api";
import { fillOutPackageForm } from "./dev_tool";
import { useDevTool } from "../../../utils/dev_tools";
import { PlatformIcon } from "../../campaigns/utils";

const CreatorSearch = ({ platform, onAdd, allAddedCreators }) => {
  const [results, setResults] = useState(null);

  const search = debounce(({ target: { value } }) => {
    if (value.length > 0) {
      apiGet(`/admin/creators/search?query=${value}&platform=${platform}`).then(
        ({ data }) => {
          if (data.creator_platforms) {
            setResults(data.creator_platforms);
          } else {
            genericError();
          }
        }
      );
    } else {
      setResults(null);
    }
  }, 250);

  const addCreator = (e, result) => {
    e.preventDefault();
    (document.getElementById("add-creator-input") as HTMLInputElement).value = "";
    setResults(null);
    onAdd(result);
  };

  return (
    <div>
      <div className="input-group">
        <span className="input-group-text">@</span>
        <input
          type="text"
          className="form-control"
          placeholder="Add Creator"
          id="add-creator-input"
          onChange={search}
          autoComplete="off"
        />
      </div>
      <div className="relative">
        {results && (
          <div className="creator-search-results">
            {results.length === 0 && (
              <div className="creator-search-results-result">No results</div>
            )}
            {results?.map((r) => {
              const alreadyAdded = allAddedCreators?.some((id) => id === r.id);
              return (
                <div key={r.id} className="creator-search-results-result">
                  {alreadyAdded ? (
                    <div className="flex space-between">
                      <PlatformIcon platform={r.platform} handle={r.handle} />
                      <div className="light-gray">(already added)</div>
                    </div>
                  ) : (
                    <div onClick={(e) => addCreator(e, r)}>
                      <PlatformIcon
                        platform={r.platform}
                        handle={r.handle}
                        className="link-like"
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const PackageForm = ({ campaign, object, onSuccess, close }) => {
  const [newlyAddedCreators, setNewlyAddedCreators] = useState([]);

  const allAddedCreators = (object.creator_platforms || []).concat(newlyAddedCreators);

  const formik = useFormik({
    initialValues: object,
    onSubmit: (values) => {
      let method: typeof apiPut | typeof apiPost, path: string;

      if (object.uuid) {
        [method, path] = [
          apiPut,
          `/admin/campaigns/${campaign.uuid}/packages/${object.uuid}`,
        ];
      } else {
        [method, path] = [apiPost, `/admin/campaigns/${campaign.uuid}/packages`];
      }
      method(path, { package: values }).then(({ data }) => {
        if (data.success) {
          onSuccess();
          close();
          toast("Saved");
        }
      });
    },
  });

  useDevTool(fillOutPackageForm, [formik, setNewlyAddedCreators]);

  return (
    <form onSubmit={formik.handleSubmit} className="pb-50" name="package-form">
      <TextField label="Cost" name="cost" type="number" prepend="$" isCurrency formik={formik} />

      <hr className="mt-3" />

      <h4>Creators</h4>

      <div className="mb-3">
        <CreatorSearch
          platform={campaign.platform}
          onAdd={(c) => {
            formik.setFieldValue(
              "creator_platform_ids",
              uniq([...(formik.values.creator_platform_ids || []), c.id])
            );
            setNewlyAddedCreators([...newlyAddedCreators, c]);
          }}
          allAddedCreators={formik.values.creator_platform_ids}
        />
      </div>

      <div className="mt-2">
        {formik.values.creator_platform_ids?.map((id) => {
          const p = allAddedCreators.find((c) => c.id === id) || {
            platform: "--",
            handle: "--",
          };

          return (
            <div className="my-2 flex space-between" key={id}>
              <div className="font-l">
                <PlatformIcon platform={p.platform} handle={p.handle} />
              </div>
              <div>
                <span
                  className="font-xs pointer"
                  onClick={() => {
                    formik.setFieldValue(
                      "creator_platform_ids",
                      pull(formik.values.creator_platform_ids, id)
                    );
                  }}
                >
                  ❌
                </span>
              </div>
            </div>
          );
        })}
      </div>

      <hr className="mt-3" />

      <div className="mt-4">
        <button type="button" className="btn btn-secondary" onClick={() => close()}>
          Cancel
        </button>
        <button type="submit" className="btn btn-primary ml-2">
          Save
        </button>
      </div>
    </form>
  );
};

export default PackageForm;

import { Link } from "react-router-dom";
import { apiPost, usePageData } from "../../utils/api";
import { genericError } from "../../utils/errors";
import Faqs from "./faq";
import { useCampaign } from "./utils";

const faqs = [
  {
    title: "When will my campaign officially start?",
    body: "Your campaign will officially start once payment is confirmed and creators are notified. You can expect updates on content creation and timelines through your dashboard.",
  },
  {
    title: "How do I track my campaign progress?",
    body: "After payment is confirmed, you'll be able to track all key aspects of your campaign—creator performance, content approvals, and campaign metrics—directly from your dashboard in real-time.",
  },
  {
    title: "Can I request a refund after purchasing a package?",
    body: "Refunds are only available in specific cases, such as if your campaign cannot be executed due to unforeseen issues with creator availability. Once a campaign has started and creators have begun work, refunds are generally not issued. If you have any concerns, please reach out to our support team for further assistance.",
  },
];

const ContentTypes = () => {
  const { data, unavailable } = usePageData();

  if (unavailable) {
    return unavailable;
  }

  const { package: pkg } = data;

  const readOnly = !!pkg.campaign.selected_package.uuid;

  const selectPackage = (e) => {
    e.preventDefault();
    if (readOnly) {
      return;
    }
    apiPost(`${pkg._link}/select_package`).then((res) => {
      if (res.data.success) {
        document.location = "/campaigns";
      } else {
        genericError();
      }
    });
  };

  return (
    <form id="main" className="container pb-10" onSubmit={(e) => selectPackage(e)}>
      <div className="row my-5 pb-3 border-bottom">
        <div className="col text-center">
          <div className="text-center fw-400 mb-1r">
            <a href="/campaigns">{pkg.campaign.name}</a>
          </div>
          <h1>Checkout</h1>
          <h4 className="fw-normal mt-2">We accept credit cards and ACH transfers</h4>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="page-subhead mb-5">
            <h3>Billing Info</h3>
            <p>
              Marty McFly
              <br />
              1239 Vermillion Street
              <br />
              West Covina, CA 91792
              <br />
              <a href="#">Change Info</a>
            </p>
          </div>

          <div className="page-subhead mb-5">
            <h3>Your Order</h3>

            <table className="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Description</th>
                  <th className="numeric">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Package 1</td>
                  <td>
                    20 creators, 32 total content
                    <br />
                    <a href="#">See package details</a>
                  </td>
                  <td className="numeric">$10,000</td>
                </tr>
                <tr className="divider-top">
                  <th colSpan={2}>XrossWorld 2.9% Service Fee</th>
                  <td className="numeric">$290</td>
                </tr>
                <tr>
                  <th colSpan={2}>Technology Service Fee</th>
                  <td className="numeric">FREE</td>
                </tr>
                <tr>
                  <th colSpan={2}>Total</th>
                  <td className="numeric fw-bold">$10,290</td>
                </tr>
              </tbody>
            </table>

            <p className="text-muted small">
              * After purchase, you'll receive an invoice from Xrossworld via Bill.com. We
              require payment before we start sourcing your creators.
            </p>

            {!readOnly && (
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  required
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  I agree to the{" "}
                  <a href="#" data-bs-toggle="modal" data-bs-target="#modalTerms">
                    Terms &amp; Conditions
                  </a>{" "}
                  of this site
                </label>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row my-5">
        <div className="col px-5">
          <h4 className="text-center fw-normal">What's Next</h4>

          <p>
            Once your payment is confirmed, your selected creators will be notified and
            start working on your campaign. You’ll be able to track campaign performance,
            monitor content creation and approvals, and review key metrics in real-time
            from your dashboard.
          </p>
        </div>
      </div>

      {readOnly ? (
        <div className="text-center">
          <Link to="/campaigns" className="btn btn-xw-secondary margin-auto">
            Back
          </Link>
        </div>
      ) : (
        <div className="row row-cta">
          <div className="col">
            <div className="page-actions mt-2">
              <div className="cta">
                <a href={null} className="btn btn-xw-secondary btn-lg">
                  Back
                </a>
                <button className="btn btn-xw-primary btn-lg" type="submit">
                  Purchase Package
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Faqs faqs={faqs} />
    </form>
  );
};

export default ContentTypes;

import { useState } from "react";
import { Drawer } from "../../../utils/offcanvas";
import { ButtonMenu, EllipsisMenu } from "../../../utils/ellipsis_menu";
import { apiDelete, apiPost, usePageData } from "../../../utils/api";
import { clickPrimaryButton, useDevTool } from "../../../utils/dev_tools";
import PackageForm from "./package_form";
import { PlatformIcon } from "../../campaigns/utils";
import { genericError } from "../../../utils/errors";
import { toast } from "react-toastify";
import { Tab, Tabs } from "react-bootstrap";
import { startCase } from "lodash";

type Status =
  | "onboarding_in_progress"
  | "onboarding_complete"
  | "packages_submitted"
  | "package_selected"
  | "campaign_in_progress"
  | "campaign_completed";

class CampaignStatus {
  campaign: { status: Status };
  currentIndex: number;
  status: string;

  order = [
    "onboarding_in_progress",
    "onboarding_complete",
    "packages_submitted",
    "package_selected",
    "campaign_in_progress",
    "campaign_completed",
  ];

  constructor(campaign) {
    this.campaign = campaign;
    this.status = campaign.status;
    this.currentIndex = this.order.findIndex((s) => s === this.status);
  }

  isAtLeast(target: Status) {
    return this.currentIndex >= this.order.findIndex((s) => s === target);
  }

  completed(target: Status) {
    return this.currentIndex + 1 >= this.order.findIndex((s) => s === target);
  }

  canTransitionTo(target: Status) {
    return this.order.findIndex((s) => s === target) === this.currentIndex + 1;
  }

  hasUnlocked(target: Status) {
    return this.currentIndex >= this.order.findIndex((s) => s === target) - 1;
  }
}

const dataPoints: [string, (c: any) => string][] = [
  ["Platform", (c) => <PlatformIcon platform={c.platform} self />],
  ["Interest categories", (c) => c.categories],
  ["Creator locations", (c) => c.creator_locations],
  ["Creator gender", (c) => c.creator_gender],
  ["Creator age ranges", (c) => c.creator_age_ranges],
  ["Agency", (c) => c.agency?.name],
  ["Brand", (c) => c.brand?.name],
  ["Objectives", (c) => c.objectives],
  ["About", (c) => c.about],
  ["Posting start date", (c) => c.posting_start_date],
  ["Posting end date", (c) => c.posting_end_date],
  ["Campaign purposes", (c) => c.campaign_purposes],
  ["Talking points", (c) => c.talking_points],
  ["Hashtags", (c) => c.hashtags],
  ["Required actions", (c) => c.required_actions],
  ["Add ons", (c) => c.add_ons],
  ["Partnership disclosures", (c) => c.partnership_disclosures],
  ["Additional guidelines", (c) => c.additional_guidelines],
  ["Creator sample", (c) => c.creator_sample],
  ["Creator sample return", (c) => c.creator_sample_return],
  ["Budget", (c) => c.budget],
  ["Created by", (c) => c.user.name],
  ["Created", (c) => new Date(c.created_at).toLocaleString()],
];

const AdminCampaignsShow = () => {
  const { data, unavailable, refetch } = usePageData();
  const [formObject, setFormObject] = useState<{ [key: string]: string }>();

  useDevTool(clickPrimaryButton);

  if (unavailable) {
    return unavailable;
  }

  const deletePackage = (pkg) => {
    apiDelete(`/admin${pkg._link}`).then((res) => {
      if (res.data.success) {
        refetch();
        toast("Package was deleted");
      } else {
        genericError();
      }
    });
  };

  const { campaign } = data;
  const campaignStatus = new CampaignStatus(campaign);

  const setStatus = (status: Status) => {
    apiPost(`/admin/campaigns/${campaign.uuid}/set_${status}`).then((res) => {
      if (res.data.success) {
        refetch();
        toast(`Campaign status updated: ${status}`);
      } else {
        genericError();
      }
    });
  };

  return (
    <div id="main" className="pb-10">
      <div className="flex space-between align-items-center">
        <h1>{campaign.name}</h1>

        <ButtonMenu
          actions={[
            {
              label: "View Matched Creators",
              link: `/admin/creators?campaignId=${campaign.uuid}`,
              target: "_blank",
            },
            {
              label: (
                <>
                  Set as ready to review
                  {campaignStatus.isAtLeast("packages_submitted") && (
                    <div className="fs-11">
                      Set on {new Date(campaign.packages_submitted_at).toLocaleString()}
                    </div>
                  )}
                </>
              ),
              action: () => setStatus("packages_submitted"),
              if: campaignStatus.hasUnlocked("packages_submitted"),
              disabled: campaignStatus.completed("packages_submitted"),
            },
            {
              label: (
                <>
                  Set as Invoice Paid
                  {campaignStatus.isAtLeast("campaign_in_progress") && (
                    <div className="fs-11">
                      Set on {new Date(campaign.invoice_paid_at).toLocaleString()}
                    </div>
                  )}
                </>
              ),
              action: () => setStatus("campaign_in_progress"),
              if: campaignStatus.hasUnlocked("campaign_in_progress"),
              disabled: campaignStatus.isAtLeast("campaign_in_progress"),
            },
          ]}
        />
      </div>

      <div className="mt-1 fs-14 text-muted">
        <PlatformIcon platform={campaign.platform} self />
        <span className="mx-2">•</span>
        {startCase(campaign.status)}
      </div>

      <Tabs className="mt-5">
        <Tab eventKey="Packages" title="Packages">
          <button
            className="btn btn-outline-primary btn-sm mt-4"
            onClick={() => {
              setFormObject({});
            }}
          >
            Add Package
          </button>

          <table className="table mt-2">
            <thead>
              <tr>
                <th className="w-25 fs-13" />
                <th className="w-25 fs-13">Cost</th>
                <th className="w-25 fs-13">Creators</th>
                <th className="text-right" />
              </tr>
            </thead>
            <tbody>
              {campaign.packages?.map((p, i) => (
                <tr key={p.uuid}>
                  <td>Package {i + 1}</td>
                  <td>
                    {parseFloat(p.cost).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </td>
                  <td>
                    {p.creator_platforms.map((p) => (
                      <div>
                        <PlatformIcon
                          platform={p.platform}
                          handle={p.handle}
                          key={p.uuid}
                        />
                      </div>
                    ))}
                  </td>
                  <td className="text-right">
                    <EllipsisMenu
                      actions={[
                        {
                          label: "Edit",
                          action: () => setFormObject(p),
                        },
                        {
                          label: "Copy",
                          action: () => setFormObject({ ...p, uuid: null }),
                        },
                        {
                          label: "Delete",
                          className: "red",
                          action: () => deletePackage(p),
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Tab>

        <Tab eventKey="Details" title="Details">
          <table className="table table-sm table-striped mt-4">
            <tbody>
              {dataPoints.map(([label, value]) => (
                <tr key={label}>
                  <td className="bold">{label}</td>
                  <td className="pl-5">
                    {(() => {
                      const v = value(campaign);
                      if (v?.length === 0) {
                        return "--";
                      } else if (Array.isArray(v)) {
                        if (v.length > 1) {
                          return (
                            <ul>
                              {v.map((i) => (
                                <li key={i}>{i}</li>
                              ))}
                            </ul>
                          );
                        } else {
                          return v[0];
                        }
                      } else {
                        return v;
                      }
                    })()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Tab>
      </Tabs>

      <Drawer
        show={!!formObject}
        onHide={() => setFormObject(undefined)}
        title={`${formObject?.uuid ? "Edit" : "Add"} Package`}
      >
        <PackageForm
          object={formObject}
          close={() => setFormObject(undefined)}
          onSuccess={refetch}
          campaign={campaign}
        />
      </Drawer>
    </div>
  );
};

export default AdminCampaignsShow;

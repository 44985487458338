import { Fragment, useState } from "react";
import { usePageData } from "../../utils/api";
import Faqs from "./faq";

const faqs = [
  {
    title: "How do I know which package is the best fit for my campaign?",
    body: "Each package is designed to meet different campaign goals. The more you invest, the broader the reach and engagement you can expect. We recommend considering your audience size, engagement goals, and budget when selecting a package. Our AI also fine-tuned the package to match your specific objectives.",
  },
  {
    title: "What happens after I purchase a package?",
    body: "Once you purchase a package, we will notify the creators that they have been chosen for your campaign. You’ll be updated when the creators start creating content, and you'll be able to review and approve the content before it's posted. After the creators have posted the content, you’ll receive updates on campaign performance, including analytics and key metrics.",
  },
  {
    title: "What happens if a creator doesn’t complete the campaign?",
    body: "If a creator doesn’t fulfill their commitment, our platform will automatically source a replacement from your matched pool. We ensure your campaign stays on track with no additional costs to you.",
  },
  {
    title: "What happens if my campaign doesn’t perform as predicted?",
    body: "While our AI-driven predictions are based on historical data and current market trends, actual performance can vary due to external factors. If your campaign doesn't meet the expected results, we’ll review the campaign data and provide recommendations to optimize future campaigns. Our goal is to continuously improve outcomes and help you achieve your marketing objectives.",
  },
  {
    title: "How is the cost of the package allocated?",
    body: "The package cost covers the fees for creators once they complete their tasks. Additionally, a separate transaction fee is charged for using the Xrossworld platform to manage your campaign efficiently and transparently. This transaction fee is added separately to maintain transparency.",
  },
  {
    title: "How are payments to creators handled?",
    body: "You won’t need to manage payments to creators—Xrossworld’s technology automatically handles it once the creators complete their tasks. This allows you to focus on campaign performance while we handle the logistics.",
  },
];

const PackageSelected = () => {
  const { data, unavailable } = usePageData();

  if (unavailable) {
    return unavailable;
  }

  const { campaign } = data;
  const { packages } = campaign;

  let packageData = [
    {
      section: "30 days prediction (aggregated)",
      rows: [
        {
          label: "Plays",
          values: packages.map(pkg => pkg.impression_range_display),
        },
        {
          label: "Interactions",
          values: packages.map(pkg => pkg.interaction_range_display),
        },
        {
          label: "Engagement",
          values: packages.map(pkg => pkg.engagement_rate_display),
        },
      ],
    },
    {
      section: "Brand Awareness",
      rows: [
        { 
          label: "Cost per Play", 
          values: packages.map(pkg => pkg.cost_per_play_display),
        },
        { 
          label: "Cost per Interaction", 
          values: packages.map(pkg => pkg.cost_per_interaction_display),
        },
      ],
    },
    {
      section: "Production Value",
      rows: [
        { 
          label: "Creators", 
          values: packages.map(pkg => pkg.creator_platforms.length),
        },
        { 
          label: "License Free Content", 
          values: packages.map(pkg => pkg.creator_platforms.length),
        },
        { 
          label: "Production Value", 
          values: packages.map(pkg => pkg.production_value.toLocaleString(
            "en-US", 
            { 
              style: "currency", 
              currency: "USD", 
              minimumFractionDigits: 0, 
              maximumFractionDigits: 0,
            }
          )),
        },
      ],
    },
    {
      section: "Creator Type",
      rows: ['Nano', 'Micro', 'Mid-tier', 'Mega', 'Celebrity'].map(tier => ({
        label: tier,
        values: packages.map(pkg => 
          pkg.creator_platforms.filter(platform => platform.size === tier.toLowerCase()).length
        ),
      }))
    },
  ];

  return (
    <div id="main" className="container px-16 pb-10">
      <div className="row my-5">
        <div className="col">
          <div className="text-center fw-400 mb-1r">
            <a href="/campaigns">{campaign.name}</a>
          </div>
          <h1 className="text-center">Select Your Campaign Package</h1>
          <p className="text-center mt-2 mx-5 px-5">
            Choose a package tailored to your campaign goals and budget. Once you select a
            package, we’ll gather the final details to get your creators started. Have
            questions - contact your Xrossworld account manager.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-5">
            <table className="table align-middle">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  {packages.map((pkg, index) => (
                    <th key={index} className="text-center neon fs-12">
                      PACKAGE {pkg.index}
                      <br />
                      <span className="fs-3">
                        {parseFloat(pkg.cost).toLocaleString(
                          "en-US", 
                          { 
                            style: "currency", 
                            currency: "USD", 
                            minimumFractionDigits: 0, 
                            maximumFractionDigits: 0,
                          },
                        )}
                      </span>
                      <br />
                      <a href={pkg._link} className="text-capitalize ms-2">
                        View Details
                      </a>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {packageData.map((section, sectionIndex) => (
                  <Fragment key={sectionIndex}>
                    <tr className="tbl_section">
                      <td colSpan={packages.length + 1}>{section.section}</td>
                    </tr>
                    {section.rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <th className="ps-4">{row.label}</th>
                        {row.values.map((value, valueIndex) => (
                          <td key={valueIndex} className="text-center">
                            {value}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
                <tr className="tbl_cta">
                  <td />
                  {packages.map((pkg) => (
                    <td key={pkg.index} className="text-center">
                      <a href={pkg._link} className="btn btn-xw-primary btn-lg">
                        View Package {pkg.index}
                      </a>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Faqs faqs={faqs} />
    </div>
  );
};

export default PackageSelected;

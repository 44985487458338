import { useFormik } from "formik";
import { apiPut, usePageData } from "../../../utils/api";
import { MultiSelect } from "../../campaigns/fields";
import { toast } from "react-toastify";

const SystemSettings = () => {
  const { data, unavailable, refetch } = usePageData();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data?.system_settings || {},
    onSubmit: (values) => {
      console.log("values", values);
      apiPut(`/admin/system_settings`, {
        system_settings: {
          notification_recipients: values.notification_recipients,
        },
      }).then((res) => {
        toast("Saved");
        refetch();
      });
    },
  });

  if (unavailable) {
    return unavailable;
  }

  return (
    <div>
      <h1>System Settings</h1>

      <form className="mt-5" onSubmit={formik.handleSubmit}>
        <MultiSelect
          label="Admin Notification Recipients"
          name="notification_recipients"
          className="w-500px"
          options={[]}
          theme="light"
          creatable
          formik={formik}
        />

        <div className="row mt-8">
          <div className="col-1">
            <button className="btn btn-primary">Save</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SystemSettings;

import classNames from "classnames";
import _, { capitalize, get } from "lodash";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";

export const formatOptions = (
  options: (
    | string
    | { value: string; label?: string | JSX.Element; exclusive?: boolean }
  )[]
) =>
  options.map((option) =>
    typeof option === "object"
      ? { label: option.value, ...option }
      : { value: option, label: option }
  );

export const SelectField = ({
  options,
  formik,
  name,
  label,
  sublabel,
  placeholder,
  onChange,
  required,
  className,
}: {
  formik: any;
  name: string;
  label?: string;
  sublabel?: string | JSX.Element;
  placeholder?: string;
  options: string[] | { value: string; label: string }[];
  onChange?: (e) => void;
  required?: boolean;
  className?: string;
}) => (
  <div className={`col ${className}`} data-form-field={name}>
    {label && <label className="mb-1">{label}</label>}
    {sublabel && <div className="form-text">{sublabel}</div>}
    <select
      className="form-select"
      name={name}
      id={name}
      onChange={(e) => {
        onChange?.(e);
        formik.handleChange(e);
      }}
      value={get(formik.values, name) || ""}
      required={required}
    >
      <option value="">{placeholder}</option>
      {formatOptions(options).map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>

    {formik.errors[name] && <div className="red mt-1">Select an option</div>}
  </div>
);

export const MultiSelect = ({
  label,
  labelHelper,
  className,
  options,
  formik,
  name,
  required,
  creatable,
  theme,
  closeMenuOnSelect = false,
}: {
  label?: string;
  labelHelper?: string;
  className?: string;
  formik: any;
  name: string;
  options: string[] | { label: string; value: string }[];
  required?: boolean;
  creatable?: boolean;
  theme?: "dark" | "light";
  closeMenuOnSelect?: boolean;
}) => {
  const SelectComponent = creatable ? CreatableSelect : ReactSelect;

  return (
    <div className={className} data-form-field={name}>
      {label && <label className="mb-1">{label}</label>}
      {labelHelper && (
        <span className="fw-normal text-body-tertiary ml-1">{labelHelper}</span>
      )}
      <SelectComponent
        options={formatOptions(options)}
        value={formatOptions(get(formik.values, name) || [])}
        onChange={(values) =>
          formik.setFieldValue(
            name,
            values.map((v) => v.value)
          )
        }
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti
        required={required}
        theme={(t) =>
          theme === "light"
            ? {
                ...t,
                colors: {
                  ...t.colors,
                  neutral20: "#dee2e6",
                  neutral10: "#f1f1f1",
                },
              }
            : {
                ...t,
                colors: {
                  ...t.colors,
                  primary50: "gray", //after select dropdown option
                  primary: "#CAFFFA", //Border and Background dropdown color
                  primary25: "gray", //Background hover dropdown color
                  neutral0: "#313131", //Background color
                  neutral10: "gray", // badges
                  neutral20: "transparent", //Border before select
                  neutral30: "gray", //Hover border
                  // neutral40: "#CAFFCA", //No options color
                  // neutral50: "#F4FFFD", //Select color
                  // neutral60: "#42FFDD", //arrow icon when click select
                  neutral80: "#F4FFFD", //Text color
                },
              }
        }
      />
      {formik.errors[name] && <div className="red mt-1">{formik.errors[name]}</div>}
    </div>
  );
};

export const RadioGroup = ({
  formik,
  label,
  name,
  options,
  className,
  onChange,
}: {
  formik: any;
  label: string;
  name: string;
  options: string[] | { value: string; label: string | JSX.Element }[];
  className?: string;
  onChange?: () => void;
}) => {
  options = formatOptions(options);

  return (
    <fieldset className={classNames("row", className)} data-form-field={name}>
      <legend className="col-form-label pt-0">{label}</legend>
      <div>
        {options.map((option) => (
          <div className="form-check form-check-inline" key={option.value}>
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                name={name}
                value={option.value || ""}
                onChange={(e) => {
                  onChange?.();
                  formik.handleChange(e);
                }}
                checked={get(formik.values, name) === option.value}
              />
              {option.label}
            </label>
          </div>
        ))}
      </div>

      {formik.errors[name] && <div className="red fw-300">{formik.errors[name]}</div>}
    </fieldset>
  );
};

export const CheckboxField = ({
  formik,
  label,
  sublabel,
  name,
  className,
}: {
  formik: any;
  label: string;
  sublabel?: string | JSX.Element;
  name: string;
  className?: string;
}) => (
  <fieldset className={classNames("row", className)} data-form-field={name}>
    <div className="mt-1">
      <div className="form-check mb-2" key={name}>
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="checkbox"
            name={name}
            onChange={(e) => {
              formik.setFieldValue(name, e.target.checked);
            }}
            checked={get(formik.values, name)}
          />
          <div>
            <div className="bold">{label}</div>
            {sublabel && <div className="form-text">{sublabel}</div>}
          </div>
        </label>
      </div>
    </div>

    {formik.errors[name] && <div className="red fw-300">{formik.errors[name]}</div>}
  </fieldset>
);

export const CheckboxGroup = ({
  formik,
  label,
  sublabel,
  name,
  options,
  className,
}: {
  formik: any;
  label: string;
  sublabel?: string | JSX.Element;
  name: string;
  options: (
    | string
    | { value: string; label?: string | JSX.Element; exclusive?: boolean }
  )[];
  className?: string;
}) => {
  const formattedOptions = formatOptions(options);

  return (
    <fieldset className={classNames("row", className)} data-form-field={name}>
      <legend className="col-form-label py-0">{label}</legend>
      {sublabel && <div className="form-text">{sublabel}</div>}
      <div className="mt-1">
        {formattedOptions.map(({ label, value }) => (
          <div className="form-check" key={value}>
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="checkbox"
                value={value}
                name={name}
                onChange={({ target: { value, checked } }) => {
                  let array = _.xor(formik.values[name], [value]);

                  const exclusives = formattedOptions
                    .filter((o) => o.exclusive)
                    .map((o) => o.value);

                  if (exclusives.length && checked) {
                    if (exclusives.includes(value)) {
                      array = [value];
                    } else {
                      array = _.difference(array, exclusives);
                    }
                  }

                  formik.setFieldValue(name, array);
                }}
                checked={(get(formik.values, name) || []).includes(value)}
              />
              {typeof label === "string" ? capitalize(label) : label}
            </label>
          </div>
        ))}
        {formik.errors[name] && <div className="red fw-300">{formik.errors[name]}</div>}
      </div>
    </fieldset>
  );
};

export const TextField = ({
  formik,
  label,
  sublabel,
  name,
  placeholder,
  className,
  prepend,
  append,
  type,
  required,
  min,
  max,
  disabled,
  isCurrency = false,
}: {
  formik: any;
  label?: string;
  sublabel?: string | JSX.Element;
  name: string;
  placeholder?: string;
  className?: string;
  prepend?: string | JSX.Element;
  append?: string | JSX.Element;
  type?: string;
  required?: boolean;
  min?: string;
  max?: string;
  disabled?: boolean;
  isCurrency?: boolean;
}) => {
  return (
    <div className={classNames("col", className)} data-form-field={name}>
      {label && <label>{label}</label>}
      {sublabel && <div className="form-text">{sublabel}</div>}
      <div className="input-group">
        {prepend && <span className="input-group-text">{prepend}</span>}
        <input
          type={type || "text"}
          name={name}
          onChange={formik.handleChange}
          className="form-control"
          placeholder={placeholder}
          value={get(formik.values, name) || ""}
          required={required}
          min={min}
          max={max}
          disabled={disabled}
        />
        {append && <span className="input-group-text">{append}</span>}
      </div>
      {formik.errors[name] && <div className="red fw-300">{formik.errors[name]}</div>}
    </div>
  );
};

export const DateField = (props) => {
  return TextField({ ...props, type: "date" });
};

export const TextArea = ({
  className,
  formik,
  label,
  sublabel,
  name,
  placeholder,
}: {
  className?: string;
  formik: any;
  label: string;
  sublabel?: string | JSX.Element;
  name: string;
  placeholder?: string;
}) => (
  <div className={classNames("", className)} data-form-field={name}>
    <label htmlFor="exampleFormControlTextarea1">{label}</label>
    {sublabel && <div className="form-text">{sublabel}</div>}

    <textarea
      name={name}
      onChange={formik.handleChange}
      className="form-control"
      rows={3}
      placeholder={placeholder}
      value={get(formik.values, name) || ""}
    ></textarea>

    {formik.errors[name] && <div className="red mt-1">{formik.errors[name]}</div>}
  </div>
);

export const Validators = {
  atLeastOneInGroup: Yup.array()
    .min(1, "Select at least one option")
    .required("Select at least one option"),
  required: Yup.string().required("Required"),
  requiredRadio: Yup.string().required("Select an option"),
};

// Entry point for the build script in your package.json
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";
import AppRoutes from "../components/routes";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { last } from "lodash";

const csrfToken = (document.querySelector("meta[name=csrf-token]") as HTMLMetaElement)
  ?.content;
axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
axios.defaults.headers.common["Accept"] = "application/json";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

document.addEventListener("DOMContentLoaded", () => {
  const rootEl = document.getElementById("root");

  const component = (
    <>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </>
  );

  createRoot(rootEl).render(component);
});

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          {Object.entries(AppRoutes).map(([path, Component]) => (
            <Route key={path} path={path} Component={Component} />
          ))}
          <Route
            path="*"
            Component={() => {
              return <div>Not found</div>;
            }}
          />
        </Routes>
      </Router>

      <ToastContainer
        position="bottom-left"
        hideProgressBar
        autoClose={1750}
        transition={Slide}
      />
    </>
  );
};

window.addEventListener("keyup", (e) => {
  if (e.key === "e" && e.ctrlKey && (window as any).devToolStack.length) {
    const item = last((window as any).devToolStack as { fn: () => void; args: any[] }[]);
    const { fn, args } = item;
    fn?.apply(this, args);
  }
});

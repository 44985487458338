import React from "react";
import { PlatformIcon } from "./utils";
import { Drawer } from "../../utils/offcanvas";
import { Tabs, Tab } from "react-bootstrap";
import CreatorPlatformProfile from "./creator_platform_profile";

const TabbedCreatorDrawer = ({
  viewCreator,
  defaultPlatform,
  onHide,
}: {
  viewCreator: any;
  defaultPlatform: string;
  onHide: () => void;
}) => {
  const platforms = (viewCreator?.creator?.creator_platforms || []).sort((a, b) =>
    a.platform === defaultPlatform ? -1 : 1
  );

  return (
    <Drawer title="" show={!!viewCreator} onHide={onHide} closeButton>
      {viewCreator && (
        <>
          <div className="text-center">
            <h3>{viewCreator.creator.name}</h3>
            <h6 className="fw-normal">{viewCreator.creator.age_gender_display}</h6>
          </div>

          <Tabs className="mt-4">
            {platforms.map((cp) => (
              <Tab
                key={cp.platform}
                eventKey={cp.platform}
                title={
                  <span>
                    <PlatformIcon
                      platform={cp.platform}
                      className="me-2"
                      handle={cp.handle}
                      contrast
                    />
                  </span>
                }
              >
                <CreatorPlatformProfile platform={cp} />
              </Tab>
            ))}
          </Tabs>
        </>
      )}
    </Drawer>
  );
};

export default TabbedCreatorDrawer;

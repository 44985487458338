import { Link } from "react-router-dom";
import { apiGet, usePageData } from "../../../utils/api";
import { PlatformIcon } from "../../campaigns/utils";
import DataTable from "../data_table";
import { useState } from "react";
import { useQuery } from "react-query";
import { platforms } from "../../campaigns/lists";
import { capitalize } from "lodash";

const emptyFilters = {
  sort: "name",
  sortDir: "asc",
  page: 1,
};

const AdminHomeIndex = () => {
  const [filters, setFilters] = useState(emptyFilters);

  const { data, refetch, isSuccess, isFetching } = useQuery({
    queryKey: ["data", filters],
    queryFn: () =>
      apiGet(`/admin/campaigns`, { params: filters }).then((res) => res.data),
    keepPreviousData: true,
  });

  const { campaigns, meta, filter_options } = data || {};

  return (
    <div id="main">
      <h2 className="fs-2_5r">Manage Campaigns</h2>

      {isSuccess && (
        <DataTable
          data={campaigns}
          schema={[
            {
              name: "name",
              value: (r) => <Link to={`/admin${r._link}`}>{r.name}</Link>,
            },
            {
              name: "platform",
              value: (r) => (
                <PlatformIcon platform={r.platform} handle={capitalize(r.platform)} />
              ),
            },
            { name: "brand", value: "brand.name" },
            { name: "agency", value: "agency.name" },
            { name: "status" },
            {
              name: "updated",
              value: (r) => (
                <>
                  <div>{r.last_updated_info.at}</div>
                  <div className="fs-14 gray">{r.last_updated_info.by}</div>
                </>
              ),
            },
            {
              name: "view",
              header: "",
              value: (r) => <Link to={`/admin${r._link}`}>{r.name}</Link>,
            },
          ]}
          filterControls={[
            { name: "search", type: "search" },
            { name: "platform", placeholder: "All Platforms", options: platforms },
            { name: "brand", placeholder: "All Brands", options: filter_options.brand },
            {
              name: "status",
              placeholder: "All Statuses",
              options: filter_options.status,
            },
          ]}
          filters={filters}
          setFilters={setFilters}
          reset={() => setFilters(emptyFilters)}
          sortable
          meta={meta}
          // widths={[150, 200, 125, 125, 150, null, 100, 100, 100, 100, 50]}
          isFetching={isFetching}
        />
      )}
    </div>
  );
};

export default AdminHomeIndex;
